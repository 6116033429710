import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { capitalize, find } from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormRegister } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { PaymentConfig } from '../apis/payment';
import { PaymentFrequency, TermPaymentConfig } from '../apis/term';
import { DATE_ERROR_FORMAT, DATE_SERVER_FORMAT } from '../functions/instalmentUtils';
import { PaymentFormFields } from '../pages/SimplifiedPayment';
import { BREAKPOINT } from '../store/reducer/BreakpointReducer';
import { RootState } from '../store/Store';
import getMaxDate from '../util/dateUtil';

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

interface PaymentFrequencyControlProps {
    paymentConfig: PaymentConfig;
    term?: TermPaymentConfig;
    control: Control<PaymentFormFields>;
    register: UseFormRegister<PaymentFormFields>;
    errors: FieldErrors<PaymentFormFields>;
    getValues: UseFormGetValues<PaymentFormFields>;
}

const formatTerm = (paymentFrequency: PaymentFrequency) => {
    if (paymentFrequency === PaymentFrequency.IN_FULL) {
        return 'Pay in full';
    }
    return capitalize(paymentFrequency.toLowerCase());
};

const PaymentFrequencyControl = (props: PaymentFrequencyControlProps) => {
    const theme = useTheme();
    const { paymentConfig, control, register, getValues, errors, term } = props;
    const [maxDate, setMaxDate] = useState<string | null>(null);
    const [firstPaymentDateError, setFirstPaymentDateError] = useState<string | null>(null);
    const startDate = moment(term?.coverStartDate).format(DATE_SERVER_FORMAT);
    const { down } = useSelector((state: RootState) => state.persistedBreakpointReducer);

    useEffect(() => {
        handleFieldChange(getValues('termSelect'), getValues('paymentStartDate'));
    }, []);

    const handleFrequencySelection = (paymentFrequency: PaymentFrequency) => {
        handleFieldChange(paymentFrequency, getValues('paymentStartDate'));
    };

    const handleFirstPaymentDateSelection = (firstPaymentDate: string) => {
        handleFieldChange(getValues('termSelect'), firstPaymentDate);
    };

    const handleFieldChange = (paymentFrequency: PaymentFrequency, selectedPaymentDate: string) => {
        const selectedTerm = find(
            paymentConfig.termPaymentConfig,
            (termPaymentConfig) => termPaymentConfig.paymentFrequency === paymentFrequency
        );

        if (selectedTerm) {
            const now = moment();
            const minFirstPaymentDate = getMaxDate([selectedTerm.coverStartDate, now.format(DATE_SERVER_FORMAT)]);
            const maxFirstPaymentDate = getMaxDate([
                selectedTerm.latestFirstPaymentDate,
                now.format(DATE_SERVER_FORMAT),
            ]);
            setMaxDate(maxFirstPaymentDate.format(DATE_SERVER_FORMAT));
            const firstPaymentDate = moment(selectedPaymentDate);
            const isValid =
                minFirstPaymentDate.isSameOrBefore(firstPaymentDate, 'days') &&
                maxFirstPaymentDate.isSameOrAfter(firstPaymentDate, 'days');
            if (paymentFrequency === PaymentFrequency.IN_FULL || isValid) {
                setFirstPaymentDateError(null);
            } else {
                setFirstPaymentDateError(
                    `First payment day must be between ${minFirstPaymentDate.format(DATE_ERROR_FORMAT)} and ${maxFirstPaymentDate.format(DATE_ERROR_FORMAT)}`
                );
            }
        }
    };

    const verticalControlOrientation = () => down(BREAKPOINT.sm) && paymentConfig.termPaymentConfig.length > 3;

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant='h5' component='h2' sx={{ mb: 2 }}>
                Frequency
            </Typography>

            <FormControl fullWidth required sx={{ mb: 2 }}>
                <FormLabel htmlFor='termSelect'>Payments made</FormLabel>
                <Controller
                    name='termSelect'
                    control={control}
                    defaultValue={undefined}
                    render={({ field }) => (
                        <>
                            <ToggleButtonGroup
                                exclusive
                                {...field}
                                {...register('termSelect')}
                                onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
                                    if (value) {
                                        (event.target as HTMLButtonElement).value = value;
                                        handleFrequencySelection(value as PaymentFrequency);
                                        field.onChange(event);
                                    }
                                }}
                                aria-label='Platform'
                                id='termSelect'
                                data-testid='termSelect'
                                color={errors?.termSelect ? 'error' : 'primary'}
                                sx={{ background: theme.palette.transparentBackGround?.['100'] }}
                                fullWidth
                                orientation={verticalControlOrientation() ? 'vertical' : 'horizontal'}
                            >
                                {paymentConfig.termPaymentConfig.map((config) => (
                                    <ToggleButton
                                        color={errors?.termSelect ? 'error' : 'primary'}
                                        sx={{
                                            flexDirection: verticalControlOrientation() ? 'row' : 'column',
                                            justifyContent: verticalControlOrientation() ? 'flex-start' : 'center',
                                        }}
                                        key={config.paymentFrequency}
                                        value={config.paymentFrequency}
                                        data-testid={`freq-${config.paymentFrequency}`}
                                    >
                                        <Typography
                                            sx={{
                                                textTransform: 'none',
                                                mr: verticalControlOrientation() ? theme.spacing(2) : 0,
                                            }}
                                        >
                                            {formatTerm(config.paymentFrequency)}
                                        </Typography>
                                        <Typography>{currencyFormatter.format(config.instalmentAmount)}</Typography>
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                            {errors.termSelect?.message && (
                                <FormHelperText error={!!errors?.termSelect}>
                                    {errors?.termSelect?.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </FormControl>

            {(!term || term.paymentFrequency !== PaymentFrequency.IN_FULL) && (
                <FormControl fullWidth required sx={{ mb: 2 }}>
                    <FormLabel htmlFor='startDate' data-testid='paymentStartDate'>
                        First payment on
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Controller
                            name={`paymentStartDate`}
                            control={control}
                            defaultValue={undefined}
                            render={({ field }) => (
                                <DatePicker
                                    onChange={(newValue: Moment | null) => {
                                        const asString = newValue == null ? '' : newValue.format(DATE_SERVER_FORMAT);
                                        handleFirstPaymentDateSelection(asString);
                                        field.onChange(asString);
                                    }}
                                    onAccept={(newValue: Moment | null) => {
                                        const asString = newValue == null ? '' : newValue.format(DATE_SERVER_FORMAT);
                                        handleFirstPaymentDateSelection(asString);
                                        field.onChange(asString);
                                    }}
                                    value={moment(field.value, DATE_SERVER_FORMAT)}
                                    inputRef={field.ref}
                                    slotProps={{
                                        textField: {
                                            id: 'startDate',
                                            fullWidth: true,
                                            helperText: firstPaymentDateError,
                                            size: 'small',
                                            error: !!firstPaymentDateError,
                                        },
                                    }}
                                    format={DATE_ERROR_FORMAT}
                                    minDate={moment(startDate, DATE_SERVER_FORMAT)}
                                    maxDate={moment(maxDate, DATE_SERVER_FORMAT)}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </FormControl>
            )}
        </Box>
    );
};

export default PaymentFrequencyControl;
