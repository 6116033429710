import { Alert, Checkbox, FormControl, FormControlLabel, FormLabel, Link, TextField, Typography } from '@mui/material';
import {
    Control,
    Controller,
    ControllerRenderProps,
    FieldErrors,
    UseFormClearErrors,
    UseFormSetError,
} from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { PaymentFrequency } from '../apis/term';
import { BankAccountFormFields } from '../pages/SimplifiedPayment';

export interface BankAccountProps<T extends BankAccountFormFields> {
    directDebitAuthorityNumber?: string;
    paymentFrequency?: PaymentFrequency;
    paymentAmount?: number;
    regularInstalmentAmount?: number;
    control: Control<T>;
    errors: FieldErrors<T>;
    setError: UseFormSetError<T>;
    clearErrors: UseFormClearErrors<T>;
}

const BankAccount = <T extends BankAccountFormFields>(props: BankAccountProps<T>) => {
    // hack to properly cast props as react-hook-form cannot handle generics
    const typeCastProps = props as unknown as BankAccountProps<BankAccountFormFields>;

    const {
        directDebitAuthorityNumber,
        paymentFrequency,
        paymentAmount,
        regularInstalmentAmount,
        control,
        setError,
        clearErrors,
        errors,
    } = typeCastProps;

    const currencyFormatter = new Intl.NumberFormat('en-nz', {
        style: 'currency',
        currency: 'NZD',
    });

    const handleConfirmation = (
        event: React.ChangeEvent<HTMLInputElement>,
        field: ControllerRenderProps<BankAccountFormFields, 'confirmAuthority'>
    ) => {
        if (event.target.checked) {
            clearErrors('confirmAuthority');
        } else {
            setError('confirmAuthority', { message: 'Error' });
        }
        field.onChange(event);
    };

    return (
        <>
            <FormControl required fullWidth sx={{ mb: '20px' }}>
                <FormLabel>Account holder</FormLabel>
                <Controller
                    name='accountHolder'
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            size='small'
                            error={errors.accountHolder !== undefined}
                            helperText={errors.accountHolder?.message}
                            inputProps={{ 'data-testid': 'name' }}
                        />
                    )}
                />
            </FormControl>

            <FormControl required fullWidth sx={{ mb: '20px' }}>
                <FormLabel>Account number</FormLabel>
                <Controller
                    name='bankAccountNumber'
                    control={control}
                    defaultValue={''}
                    render={({ field }) => {
                        return (
                            <ReactInputMask
                                mask='99-9999-9999999-99'
                                alwaysShowMask={true}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                            >
                                <TextField
                                    size='small'
                                    autoComplete='no'
                                    error={errors.bankAccountNumber !== undefined}
                                    helperText={errors.bankAccountNumber?.message}
                                    {...field}
                                    inputProps={{ 'data-testid': 'bankAccount' }}
                                />
                            </ReactInputMask>
                        );
                    }}
                />
            </FormControl>

            {paymentFrequency && regularInstalmentAmount && paymentFrequency !== PaymentFrequency.IN_FULL && (
                <Typography sx={{ mt: 1, mb: 2 }}>
                    Your regular instalment amount is{' '}
                    <span style={{ display: 'inline', fontWeight: 600, fontSize: 18 }} data-testid='regularInstalment'>
                        {currencyFormatter.format(regularInstalmentAmount)}
                    </span>
                    <span style={{ display: 'inline', fontSize: 11 }}> (includes processing fee).</span>
                </Typography>
            )}

            {paymentAmount && (
                <FormControlLabel
                    control={
                        <Controller
                            name='confirmAuthority'
                            control={control}
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                    onChange={(e) => handleConfirmation(e, field)}
                                />
                            )}
                        />
                    }
                    label={
                        <Typography variant='subtitle2'>{`I confirm that I have sole authority over this bank account, and I authorise a payment of ${currencyFormatter.format(paymentAmount)} from this bank account today`}</Typography>
                    }
                />
            )}

            {errors.confirmAuthority?.message && (
                <Alert severity='error'>Sole authority confirmation is required</Alert>
            )}

            <Typography variant='subtitle2' sx={{ mt: 2 }}>
                Authority to accept direct debits. Authorisation code {directDebitAuthorityNumber}.
            </Typography>

            <Typography variant='subtitle2' sx={{ mt: 2 }}>
                By completing the payment I/we authorise you to debit my/our account with the amounts of direct debits
                from Simfuni Ltd, with the authorisation code specified on this authority, in accordance with the{' '}
                <Link target='_blank' href='https://simfuni.com/direct-debit-terms-and-conditions'>
                    direct debit terms and conditions
                </Link>{' '}
                until further notice.
            </Typography>
        </>
    );
};

export default BankAccount;
