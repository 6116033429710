import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Button, Typography } from '@mui/material';
import { find, union } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InvoiceStatus, fetchInvoiceStatus } from '../../apis/checkout';
import {
    CheckoutPaymentConfig,
    PaymentDetail,
    PaymentStatus,
    fetchCheckoutPaymentConfig,
    fetchCheckoutPaymentTermAdjustment,
} from '../../apis/payment';
import { Charge, PaymentFrequency, TermPaymentConfig, TermPaymentMethod } from '../../apis/term';
import AgreeTerms from '../../components/AgreeTerms';
import { LoadingButton } from '../../components/LoadingButton';
import PageLoading from '../../components/PageLoading';
import PaymentMethodControl from '../../components/PaymentMethodControl';
import PaymentStartDateControl from '../../components/PaymentStartDateControl';
import PaymentSummary from '../../components/PaymentSummary';
import { DATE_SERVER_FORMAT } from '../../functions/instalmentUtils';
import useWatchPaymentFormFields from '../../hooks/usePaymentPlan';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { InvoicePaymentMethod } from '../../store/reducer/SelectedPaymentMethodReducer';
import { InvoiceSummary } from '../../type/Types';
import getMaxDate from '../../util/dateUtil';
import submitPaymentRequest from '../../util/submitPaymentRequest';
import getPaymentFormSchema from '../SimplifiedPayment/paymentFormSchema';

export type BankAccountFormFields = {
    accountHolder: string;
    bankAccountNumber: string;
    confirmAuthority: boolean;
};

export type CybersourceFormFields = {
    expiry: string;
};

export type PaymentFormFields = BankAccountFormFields &
    CybersourceFormFields & {
        termSelect: PaymentFrequency;
        paymentStartDate: string;
        paymentMethod: InvoicePaymentMethod;
        termsAccepted: boolean;
    };

type Props = { back?: () => void };

export default function StandardPayment({ back }: Readonly<Props>) {
    const invoice: InvoiceSummary = useAppSelector((state) => state.persistedInvoiceReducer).value;
    const persistedTerm = useAppSelector((state) => state.persistedTermReducer).value;
    const paymentPlanState: PaymentDetail = useAppSelector((state) => state.persistedPaymentPlanReducer).value;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [checkoutPaymentConfig, setCheckoutPaymentConfig] = useState<CheckoutPaymentConfig | null>(null);
    const [term, setTerm] = useState<TermPaymentConfig | undefined>(undefined);
    const [relevantTransactionFees, setRelevantTransactionFees] = useState<Charge[]>([]);
    const [loadingSubmitRes, setLoadingSubmitRes] = useState(false);
    const [upfrontPaymentInclFees, setUpfrontPaymentInclFees] = useState<number>();
    const [allowedPaymentMethods, setAllowedPaymentMethods] = useState<TermPaymentMethod[]>([]);
    const [cardPaymentError, setCardPaymentError] = useState<string | undefined>(undefined);
    const preSubmitRef = useRef<{ preSubmitHelper: () => Promise<string> } | undefined>();
    const defaultFirstPaymentDate = getMaxDate([
        invoice.portfolio?.policies?.[0]?.currentPolicyVersion?.anniversaryDate ?? moment().format(DATE_SERVER_FORMAT),
        moment().format(DATE_SERVER_FORMAT),
    ]).format(DATE_SERVER_FORMAT);

    const postSubmitAction: {
        [key in InvoiceStatus]?: (loadCheckoutConfig: () => Promise<void>) => void | Promise<void>;
    } = {
        [InvoiceStatus.PAID]: (_loadCheckoutConfig) => navigate('/payment-complete'),
        [InvoiceStatus.PROCESSING]: (_loadCheckoutConfig) => navigate('/payment-processing'),
        [InvoiceStatus.DECLINED]: (loadCheckoutConfig) => {
            setCardPaymentError('Payment failed, please try again.');
            return loadCheckoutConfig();
        },
        [InvoiceStatus.IN_PROGRESS]: (loadCheckoutConfig) => loadCheckoutConfig(),
    };

    const {
        control,
        register,
        setError,
        clearErrors,
        getValues,
        setValue,
        formState: { errors, isDirty },
        handleSubmit,
    } = useForm<PaymentFormFields>({
        resolver: yupResolver(getPaymentFormSchema(allowedPaymentMethods)),
        defaultValues: {
            paymentStartDate: getMaxDate([paymentPlanState.firstPaymentDate, defaultFirstPaymentDate]).format(
                DATE_SERVER_FORMAT
            ),
            termSelect: paymentPlanState.paymentFrequency,
            paymentMethod: paymentPlanState.paymentMethodType || InvoicePaymentMethod.NOT_SET,
        },
    });
    const formController = {
        control,
        register,
        setError,
        clearErrors,
        getValues,
        setValue,
        errors,
    };

    const queryTermUpdate = (firstPaymentDate: string): Promise<void> => {
        return fetchCheckoutPaymentTermAdjustment(invoice.uuid, firstPaymentDate).then((newPaymentConfig) => {
            setCheckoutPaymentConfig({
                ...checkoutPaymentConfig,
                paymentConfig: newPaymentConfig,
            } as CheckoutPaymentConfig);
        });
    };

    const loadCheckoutConfig = () => {
        const firstPaymentDate = getMaxDate([paymentPlanState.firstPaymentDate, defaultFirstPaymentDate]).format(
            DATE_SERVER_FORMAT
        );
        return fetchCheckoutPaymentConfig(invoice.uuid, firstPaymentDate).then((config) => {
            setCheckoutPaymentConfig(config);
            const paymentMethodUnion = union(
                config.paymentConfig.termPaymentConfig
                    .filter((config) => config.paymentFrequency === persistedTerm.paymentFrequency)
                    .flatMap((termPaymentConfig) => termPaymentConfig.paymentMethods)
            );
            setAllowedPaymentMethods(paymentMethodUnion);
            setTerm(
                find(
                    config.paymentConfig.termPaymentConfig,
                    (termPaymentConfig) => termPaymentConfig.paymentFrequency === persistedTerm.paymentFrequency
                )
            );
            setValue('termSelect', persistedTerm.paymentFrequency);
        });
    };

    // fetches the invoice status and if applicable the payment config on component mount
    useEffect(() => {
        fetchInvoiceStatus(invoice.uuid).then((status) => postSubmitAction[status]?.(loadCheckoutConfig));
    }, [invoice, persistedTerm]);

    useWatchPaymentFormFields({
        control,
        checkoutPaymentConfig,
        setTerm,
        setRelevantTransactionFees,
        setUpfrontPaymentInclFees,
        setAllowedPaymentMethods,
        dispatch,
    });

    const submitHandler: SubmitHandler<PaymentFormFields> = (data) => {
        setLoadingSubmitRes(true);
        const paymentMethod = data.paymentMethod;

        submitPaymentRequest(invoice.uuid, data, allowedPaymentMethods, preSubmitRef)
            .then((paymentResponse) => {
                if (paymentResponse.status === PaymentStatus.SUCCEED) {
                    navigate('/payment-complete');
                } else if (paymentResponse.status === PaymentStatus.PROCESSING) {
                    if (paymentResponse.sessionUrl) {
                        window.location.href = paymentResponse.sessionUrl;
                    } else {
                        navigate('/payment-processing');
                    }
                }
            })
            .catch((e) => {
                if (e.response && e.response.status === 400) {
                    if (paymentMethod === InvoicePaymentMethod.DIRECT_DEBIT) {
                        setError('bankAccountNumber', { message: 'Invalid bank/branch' });
                    }
                }
            })
            .finally(() => setLoadingSubmitRes(false));
    };

    if (!checkoutPaymentConfig || !term) {
        return <PageLoading />;
    }

    return (
        <>
            <Typography variant='h3' component='h1' sx={{ mt: 2 }}>
                Payment
            </Typography>
            <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                <Box sx={{ width: '100%' }}>
                    <form onSubmit={handleSubmit(submitHandler)}>
                        <PaymentStartDateControl term={term} {...formController} queryPaymentConfig={queryTermUpdate} />
                        <PaymentMethodControl
                            paymentFrequency={persistedTerm.paymentFrequency}
                            paymentAmount={upfrontPaymentInclFees}
                            paymentMethods={allowedPaymentMethods}
                            isDirty={isDirty}
                            preSubmitRef={preSubmitRef}
                            cardPaymentError={cardPaymentError}
                            {...formController}
                        />
                        <PaymentSummary
                            term={term}
                            transactionFees={relevantTransactionFees}
                            firstPaymentDate={getValues('paymentStartDate')}
                        />
                        <AgreeTerms
                            invoiceUuid={invoice.uuid}
                            term={term}
                            firstPaymentDate={getValues('paymentStartDate')}
                            {...formController}
                        />
                        {back != null ? (
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Button sx={{ width: '100%' }} variant='text' onClick={back}>
                                    Back
                                </Button>
                                <LoadingButton
                                    sx={{ width: '100%' }}
                                    loading={loadingSubmitRes}
                                    type='submit'
                                    variant='contained'
                                >
                                    Pay
                                </LoadingButton>
                            </Box>
                        ) : (
                            <LoadingButton
                                loading={loadingSubmitRes}
                                type='submit'
                                variant='contained'
                                sx={{ mt: 1 }}
                                data-testid='PROCE'
                            >
                                Confirm & Pay
                            </LoadingButton>
                        )}
                    </form>
                </Box>
            </Box>
        </>
    );
}
