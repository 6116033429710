import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Typography } from '@mui/material';
import { debounce, union } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchInvoiceStatus, InvoiceStatus } from '../../apis/checkout';
import {
    CheckoutPaymentConfig,
    fetchCheckoutPaymentConfig,
    fetchCheckoutPaymentTermAdjustment,
    PaymentDetail,
    PaymentStatus,
} from '../../apis/payment';
import { Charge, PaymentFrequency, TermPaymentConfig, TermPaymentMethod } from '../../apis/term';
import AgreeTerms from '../../components/AgreeTerms';
import { LoadingButton } from '../../components/LoadingButton';
import PageLoading from '../../components/PageLoading';
import PaymentFrequencyControl from '../../components/PaymentFrequencyControl';
import PaymentMethodControl from '../../components/PaymentMethodControl';
import PaymentPlanSummary from '../../components/PaymentSummary';
import PortfolioSummary from '../../components/PortfolioSummary';
import { DATE_SERVER_FORMAT } from '../../functions/instalmentUtils';
import useWatchPaymentFormFields from '../../hooks/usePaymentPlan';
import { BREAKPOINT } from '../../store/reducer/BreakpointReducer';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { setPageWider } from '../../store/reducer/PageWidthReducer';
import { InvoicePaymentMethod } from '../../store/reducer/SelectedPaymentMethodReducer';
import { RootState } from '../../store/Store';
import { InvoiceSummary } from '../../type/Types';
import getMaxDate from '../../util/dateUtil';
import submitPaymentRequest from '../../util/submitPaymentRequest';
import getPaymentFormSchema from './paymentFormSchema';

export type BankAccountFormFields = {
    accountHolder: string;
    bankAccountNumber: string;
    confirmAuthority: boolean;
};

export type CybersourceFormFields = {
    expiry: string;
};

export type PaymentFormFields = BankAccountFormFields &
    CybersourceFormFields & {
        termSelect: PaymentFrequency;
        paymentStartDate: string;
        paymentMethod: InvoicePaymentMethod;
        termsAccepted: boolean;
    };

const SimplifiedPayment = () => {
    const invoice: InvoiceSummary = useSelector((state: RootState) => state.persistedInvoiceReducer).value;
    const { up, down } = useSelector((state: RootState) => state.persistedBreakpointReducer);

    const [checkoutPaymentConfig, setCheckoutPaymentConfig] = useState<CheckoutPaymentConfig | null>(null);
    const [term, setTerm] = useState<TermPaymentConfig | undefined>(undefined);
    const [relevantTransactionFees, setRelevantTransactionFees] = useState<Charge[]>([]);
    const [loadingSubmitRes, setLoadingSubmitRes] = useState(false);
    const [upfrontPaymentInclFees, setUpfrontPaymentInclFees] = useState<number>();
    const [allowedPaymentMethods, setAllowedPaymentMethods] = useState<TermPaymentMethod[]>([]);
    const [cardPaymentError, setCardPaymentError] = useState<string | undefined>(undefined);
    const preSubmitRef = useRef<{ preSubmitHelper: () => Promise<string> } | undefined>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const paymentPlanState: PaymentDetail = useAppSelector(
        (state: RootState) => state.persistedPaymentPlanReducer
    ).value;

    const postSubmitAction: { [key in InvoiceStatus]?: () => void } = {
        [InvoiceStatus.PAID]: () => navigate('/payment-complete'),
        [InvoiceStatus.PROCESSING]: () => navigate('/payment-processing'),
        [InvoiceStatus.DECLINED]: () => setCardPaymentError('Payment failed, please try again.'),
    };

    const {
        control,
        register,
        setError,
        clearErrors,
        watch,
        getValues,
        setValue,
        formState: { errors, isDirty },
        handleSubmit,
    } = useForm<PaymentFormFields>({
        resolver: yupResolver(getPaymentFormSchema(allowedPaymentMethods)),
        defaultValues: {
            paymentStartDate: paymentPlanState.firstPaymentDate || moment().format(DATE_SERVER_FORMAT),
            termSelect: paymentPlanState.paymentFrequency,
            paymentMethod: paymentPlanState.paymentMethodType || InvoicePaymentMethod.NOT_SET,
        },
    });
    const formController = {
        control,
        register,
        setError,
        clearErrors,
        watch,
        getValues,
        setValue,
        errors,
    };
    const watchPaymentFrequency = watch('termSelect');
    const watchFirstPaymentDate = watch('paymentStartDate');

    // sets the page width to "wider" aka 862px as controlled by the Layout component
    useEffect(() => {
        dispatch(setPageWider(true));
        return () => {
            dispatch(setPageWider(false));
        };
    }, []);

    // fetches the invoice status and if applicable the payment config on component mount
    useEffect(() => {
        fetchInvoiceStatus(invoice.uuid)
            .then((status) => postSubmitAction[status]?.())
            .then(() => fetchCheckoutPaymentConfig(invoice.uuid, watchFirstPaymentDate))
            .then((config) => {
                setCheckoutPaymentConfig(config);
            });
    }, [invoice]);

    useEffect(() => {
        if (checkoutPaymentConfig == null) {
            return;
        }

        if (watchPaymentFrequency == null) {
            let defaultTerm = checkoutPaymentConfig.paymentConfig.termPaymentConfig.find(
                (config) => config.paymentFrequency === PaymentFrequency.MONTHLY
            );
            if (defaultTerm == null) {
                defaultTerm = checkoutPaymentConfig.paymentConfig.termPaymentConfig[0];
            }
            setValue('termSelect', defaultTerm.paymentFrequency);

            const now = moment();
            const minFirstPaymentDate = getMaxDate([defaultTerm.coverStartDate, now.format(DATE_SERVER_FORMAT)]);
            setValue('paymentStartDate', minFirstPaymentDate.format(DATE_SERVER_FORMAT));

            return;
        }

        const paymentMethodUnion = union(
            checkoutPaymentConfig.paymentConfig.termPaymentConfig
                .filter((term) => term.paymentFrequency === watchPaymentFrequency)
                .flatMap((termPaymentConfig) => termPaymentConfig.paymentMethods)
        );
        setAllowedPaymentMethods(paymentMethodUnion);
    }, [checkoutPaymentConfig, watchPaymentFrequency]);

    // refreshes the payment and term configuration each time the first payment date is updated (and is valid)
    useEffect(() => {
        if (!moment(watchFirstPaymentDate, DATE_SERVER_FORMAT).isValid()) {
            console.log('invalid date');
            return;
        }
        if (isDirty) {
            debounceFn(watchFirstPaymentDate);
        }
    }, [watchFirstPaymentDate]);

    const debounceFn = useMemo(
        () =>
            debounce((firstPaymentDate) => {
                fetchCheckoutPaymentTermAdjustment(invoice.uuid, firstPaymentDate).then((newPaymentConfig) =>
                    setCheckoutPaymentConfig({
                        ...checkoutPaymentConfig,
                        paymentConfig: newPaymentConfig,
                    } as CheckoutPaymentConfig)
                );
            }, 400),
        []
    );

    useWatchPaymentFormFields({
        control,
        checkoutPaymentConfig,
        setTerm,
        setRelevantTransactionFees,
        setUpfrontPaymentInclFees,
        setAllowedPaymentMethods,
        dispatch,
    });

    const submitHandler: SubmitHandler<PaymentFormFields> = (data) => {
        setLoadingSubmitRes(true);
        const paymentMethod = data.paymentMethod;

        submitPaymentRequest(invoice.uuid, data, allowedPaymentMethods, preSubmitRef)
            .then((paymentResponse) => {
                if (paymentResponse.status === PaymentStatus.SUCCEED) {
                    navigate('/payment-complete');
                } else if (paymentResponse.status === PaymentStatus.PROCESSING) {
                    if (paymentResponse.sessionUrl) {
                        window.location.href = paymentResponse.sessionUrl;
                    } else {
                        navigate('/payment-processing');
                    }
                }
            })
            .catch((e) => {
                if (e.response && e.response.status === 400) {
                    if (paymentMethod === InvoicePaymentMethod.DIRECT_DEBIT) {
                        setError('bankAccountNumber', { message: 'Invalid bank/branch' });
                    }
                }
            })
            .finally(() => setLoadingSubmitRes(false));
    };

    if (!checkoutPaymentConfig) {
        return <PageLoading />;
    }

    return (
        <Box sx={{ maxWidth: down(BREAKPOINT.lg) ? '580px' : '100%', m: 'auto' }}>
            {down(BREAKPOINT.lg) && (
                <PortfolioSummary portfolio={checkoutPaymentConfig.paymentConfig.portfolio} compact={true} />
            )}
            <Typography variant='h3' component='h1' sx={{ mt: 2 }}>
                Payment
            </Typography>
            <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                <Box sx={{ width: up(BREAKPOINT.lg) ? '55%' : '100%' }}>
                    <form onSubmit={handleSubmit(submitHandler)}>
                        <PaymentFrequencyControl
                            paymentConfig={checkoutPaymentConfig.paymentConfig}
                            term={term}
                            {...formController}
                        />
                        {allowedPaymentMethods?.length > 0 && (
                            <PaymentMethodControl
                                paymentFrequency={watchPaymentFrequency}
                                paymentAmount={upfrontPaymentInclFees}
                                paymentMethods={allowedPaymentMethods}
                                isDirty={isDirty}
                                preSubmitRef={preSubmitRef}
                                cardPaymentError={cardPaymentError}
                                {...formController}
                            />
                        )}
                        <PaymentPlanSummary
                            term={term}
                            transactionFees={relevantTransactionFees}
                            firstPaymentDate={watchFirstPaymentDate}
                        />
                        <AgreeTerms invoiceUuid={invoice.uuid} term={term} {...formController} />
                        <LoadingButton
                            loading={loadingSubmitRes}
                            type='submit'
                            variant='contained'
                            sx={{ mt: 1 }}
                            data-testid='PROCE'
                        >
                            Confirm & Pay
                        </LoadingButton>
                    </form>
                </Box>
                {up(BREAKPOINT.lg) && (
                    <Box sx={{ width: '45%' }}>
                        <PortfolioSummary portfolio={checkoutPaymentConfig.paymentConfig.portfolio} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default SimplifiedPayment;
